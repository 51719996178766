import React, { useContext } from 'react';
import { Route, RouteComponentProps, useParams } from 'react-router';
import { Switch } from 'react-router-dom';
import { configs } from './router-configuration';
import ServerContext from './contexts/serverContext';
import {
    buildRoute,
    findAliasByPath,
    findRoute,
    findRouteByAlias,
    mergeRoutes,
} from './components/utils/router-util/router-util';
import { useExperienceContent } from './hooks/use-server-content';
import { useCategoryViewContent } from './views/category-view/hooks/use-category-view-content';
import { useSamCategoryViewContent } from './views/sam-category-view/hooks/use-category-view-content';
import { useSubCategoryViewContent } from './views/sub-category-view/hooks/use-sub-category-view-content';
import loadable from '@loadable/component';
import AppConfigurationService from './services/app-configuration-service/app-configuration-service';
import { BrandUtil } from './components/utils/brand-util/brand-util';
import { TrailerType } from './models/experiencefragments/trailer-checklist';
import { GVTPageProps } from './views/gvt-landing-view/gvt-landing-view';
import { ArticleOverviewViewProps } from './views/dyf-feature-article-overview-view/dyf-feature-article-overview-view';

const {
    currentDomain,
    currentLanguageRegionCode,
    brand,
} = new AppConfigurationService();
const brandUtil = new BrandUtil();
const VehicleYmmView = loadable(() =>
    import('./views/vehicle-ymm-view/vehicle-ymm-view')
);
const FMVehicleYmmView = loadable(() =>
    import('./views/fm-vehicle-ymm-view/fm-vehicle-ymm-view')
);
const VehicleNotFoundView = loadable(() =>
    import('./views/vehicle-not-found-view/vehicle-not-found-view')
);
const CategoryHandler = loadable(() =>
    import('./views/category-view/category-view-handler')
);
const SamCategoryHandler = loadable(() =>
    import('./views/sam-category-view/sam-category-view-handler')
);
const TrailerChecklistDetailHandler = loadable(() =>
    import('./views/trailer-checklist-view/trailer-checklist-detail-handler')
);
const VehicleVinView = loadable(() =>
    import('./views/vehicle-vin-view/vehicle-vin-view')
);
const FMVehicleVinView = loadable(() =>
    import('./views/fm-vehicle-vin-view/fm-vehicle-vin-view')
);
const PreImagineVehicleVinView = loadable(() =>
    import('./views/preimagine-vehicle-vin-view/preimagine-vehicle-vin-view')
);
const VehicleDashboardView = loadable(() =>
    import('./views/vehicle-dashboard-view/vehicle-dashboard-view')
);
const FMVehicleDashboardView = loadable(() =>
    import('./views/fm-vehicle-dashboard-view/fm-vehicle-dashboard-view')
);
const VehicleHealthView = loadable(() =>
    import('./views/vehicle-health-view/vehicle-health-view')
);
const WarrantyWrapper = loadable(() =>
    import('./components/sections/warranty-information/warranty-controller')
);
const WarrantyInformationViewRoute = loadable(() =>
    import('./views/warranty-information-view/warranty-information-view')
);
const ExtendedServiceBusinessRoute = loadable(() =>
    import(
        './views/extended-service-business-view/extended-service-business-view'
    )
);
const ServiceHistoryView = loadable(() =>
    import('./views/service-history-view/service-history-view')
);
const VehicleHealthReportView = loadable(() =>
    import('./views/vehicle-health-report-view/vehicle-health-report-view')
);
const TireFinderView = loadable(() =>
    import('./views/tirefinder-view/tirefinder-view')
);
const FordRidesView = loadable(() =>
    import('./views/ford-rides-view/ford-rides-view')
);
const RecallsDetailsPage = loadable(() =>
    import('./support/pages/recalls-details/recalls-details')
);
const RecallsCampaignDetailsPage = loadable(() =>
    import(
        './support/pages/recalls-campaign-details/recalls-campaign-details-container'
    )
);

const RedeemRebatesView = loadable(() =>
    import('./views/redeem-rebates-view/redeem-rebates-view')
);

const CouponsDetailView = loadable(() =>
    import('./views/coupons-detail-view/coupons-detail-view')
);
const CouponsView = loadable(() => import('./views/coupons-view/coupons-view'));
const RecallsPage = loadable(() => import('./support/pages/recalls/recalls'));
const TakataRecallsPage = loadable(() =>
    import('./support/pages/recalls/takata-recalls')
);
const OwnerManualPage = loadable(() =>
    import('./support/pages/owner-manual/owner-manual')
);
const OwnerManualDetailsPage = loadable(() =>
    import('./support/pages/owner-manual-details/owner-manual-details')
);
const OwnerManualAiChat = loadable(() =>
    import('./support/pages/owner-manual-ai-chat/owner-manual-ai-chat')
);
const ComingSoonPage = loadable(() =>
    import('./support/pages/coming-soon/coming-soon-view')
);
const OwnerManualSitemapPage = loadable(() =>
    import('./support/pages/owner-manual-sitemap/owner-manual-sitemap')
);
const LiveStreamView = loadable(() =>
    import('./views/live-stream-view/live-stream-view')
);
const HomePage = loadable(() => import('./views/home-page/home-page'));
const ProTrailerBackupAssist = loadable(() =>
    import('./views/pro-trailer-backup-assist/pro-trailer-backup-assist')
);
const HomePreimagine = loadable(() =>
    import('./views/home-preimagine/home-preimagine')
);
const CxHomePage = loadable(() => import('./views/cx-home-page/cx-home-page'));
const CxHandler = loadable(() => import('./views/cx-view/cx-view-handler'));
const GuidedVirtualTour = loadable(() =>
    import('./views/gvt-landing-view/gvt-landing-view')
) as React.FC<GVTPageProps>;

const MaintenanceScheduleLandingView = loadable(() =>
    import(
        './views/maintenance-schedule-landing-view/maintenance-schedule-landing-view'
    )
);
const PageNotFoundView = loadable(() =>
    import('./views/page-not-found-view/page-not-found-view')
);
const AstuteSiteMap = loadable(() =>
    import('./support/components/astute-site-map/astute-site-map')
);
const HtmlSiteMap = loadable(() =>
    import('./support/components/html-sitemap/html-site-map')
);
const OsbWrapper = loadable(() =>
    import('./components/sections/owners-osb/osb-controller')
);
const OsbStartView = loadable(() =>
    import('./components/sections/owners-osb/shell-views/osb-start-view')
);
const OsbDealerView = loadable(() =>
    import('./components/sections/owners-osb/shell-views/osb-dealer-view')
);
const OsbServicesView = loadable(() =>
    import('./components/sections/owners-osb/shell-views/osb-services-view')
);
const OsbBookingConfirmationView = loadable(() =>
    import(
        './components/sections/owners-osb/shell-views/osb-booking-confirmation-view'
    )
);
const OsbCalenderView = loadable(() =>
    import('./components/sections/owners-osb/shell-views/osb-calendar-view')
);
const OsbBookingCancelView = loadable(() =>
    import(
        './components/sections/owners-osb/shell-views/osb-booking-cancel-view'
    )
);
const OsbDeliveryView = loadable(() =>
    import('./components/sections/owners-osb/shell-views/osb-delivery-view')
);
const OsbServiceLocationView = loadable(() =>
    import(
        './components/sections/owners-osb/shell-views/osb-service-location-view'
    )
);
const OsbPersonalDetailsView = loadable(() =>
    import(
        './components/sections/owners-osb/shell-views/osb-personal-details-view'
    )
);
const OsbBookingAccessCodeView = loadable(() =>
    import(
        './components/sections/owners-osb/shell-views/osb-booking-access-code-view'
    )
);
const OsbBookingRetrieveView = loadable(() =>
    import(
        './components/sections/owners-osb/shell-views/osb-booking-retrieve-view'
    )
);
const OsbBookingRetrieveSummaryView = loadable(() =>
    import(
        './components/sections/owners-osb/shell-views/osb-booking-retrieve-summay-view'
    )
);
const OsbBookingReviewView = loadable(() =>
    import(
        './components/sections/owners-osb/shell-views/osb-booking-review-view'
    )
);
const OsbVehicleView = loadable(() =>
    import('./components/sections/owners-osb/shell-views/osb-vehicle-view')
);
const PrivacyPolicyPortalView = loadable(() =>
    import(
        './views/privacy-policy/privacy-policy-portal/privacy-policy-portal-view'
    )
);

const HowToViewVideo = loadable(() =>
    import('./views/htv-video-page/htv-video-page')
);
const HowToVideoView = loadable(() =>
    import('./views/how-to-video-view/how-to-video-view')
);
const HTVVideoLibraryView = loadable(() =>
    import('./views/htv-video-library/htv-video-library-view')
);
const HTVCategoryLibrary = loadable(() =>
    import('./views/htv-category-library/htv-category-library')
);
const SiteMapView = loadable(() =>
    import('./views/sitemap-view/site-map-view')
);
const DyfLandingView = loadable(() =>
    import('./views/dyf-landing-view/dyf-landing-view')
);

const DyfFeatureArticleOverview = loadable(() =>
    import(
        './views/dyf-feature-article-overview-view/dyf-feature-article-overview-view'
    )
) as React.FC<ArticleOverviewViewProps>;

const DyfRelatedArticlesView = loadable(() =>
    import('./views/dyf-related-articles-view/dyf-related-articles-view')
);
const RoadBlockPage = loadable(() =>
    import('./views/road-block-page/road-block-view')
);
const DeviceAwareNaEuView = loadable(() =>
    import('./views/device-aware-NaEu-view/device-aware-NaEu-view')
);

const AstuteSearch = loadable(() =>
    import('./support/components/astute-search/astute-search')
);

const CategoryPageView = loadable(() =>
    import('./support/components/category-page/category-page-view')
);

const SubCategoryPageView = loadable(() =>
    import('./support/components/subcategory-page/subcategory-page-view')
);

const AstuteTopic = loadable(() =>
    import('./support/components/astute-topic/astute-topic')
);

const PrivacyCenterView = loadable(() =>
    import('./views/privacy-policy/privacy-center-view/privacy-center-view')
);

const PrivacyOptionsView = loadable(() =>
    import('./views/privacy-policy/privacy-options-view/privacy-options-view')
);
const SyncMapsUpdatesView = loadable(() =>
    import('./views/sync-maps-updates-view/sync-maps-updates-view')
);
const HistoricalReleaseNotes = loadable(() =>
    import('./support/pages/historical-release-notes/historical-release-notes')
);
const GxpCustomUIView = loadable(() =>
    import('./views/gxp-customui-view/gxp-customui-view')
);
const CITTView = loadable(() => import('./views/citt-view/citt-view'));
const CollisionAssistanceView = loadable(() =>
    import('./views/collision-assistance-view/collision-assistance-view')
);
const TSBView = loadable(() => import('./views/tsb-view/tsb-view'));
const TrailerChecklistView = loadable(() =>
    import('./views/trailer-checklist-view/trailer-checklist-view')
);

const TowingExperienceView = loadable(() =>
    import(
        './views/ultimate-towing-experience-view/ultimate-towing-experience-view'
    )
);

const YFAOptInView = loadable(() =>
    import('./views/yfa/yfa-opt-in-view/yfa-opt-in-view')
);

const YFAConfirmationView = loadable(() =>
    import('./views/yfa/yfa-confirmation-view/yfa-confirmation-view')
);

const YFAEnrolledView = loadable(() =>
    import('./views/yfa/yfa-enrolled-view/yfa-enrolled-view')
);

const YfaInvitedView = loadable(() =>
    import('./views/yfa/yfa-invited-view/yfa-invited-view')
);

const YfaTermsConditions = loadable(() =>
    import('./views/yfa/yfa-terms-conditions/yfa-terms-conditions')
);

const YfaErrorView = loadable(() =>
    import('./views/yfa/yfa-error-view/yfa-error-view')
);

const ServicePriceCalculatorView = loadable(() =>
    import(
        './views/service-price-calculator-view/service-price-calculator-view'
    )
);

const OilLifeDetailsView = loadable(() =>
    import('./views/oil-life-details-view/oil-life-details-view')
);

const SlowTireLeakDetailsView = loadable(() =>
    import('./views/slow-tire-leak-details-view/slow-tire-leak-details-view')
);

const ServiceRemindersDetailsView = loadable(() =>
    import(
        './views/service-reminders-details-view/service-reminders-details-view'
    )
);
const BlueCruisePage = loadable(() =>
    import('./support/pages/bluecruise/bluecruise')
);

const DiagnosticsDetailsView = loadable(() =>
    import('./views/diagnostic-details-view/diagnostic-details-view')
);

const LincolnCalmView = loadable(() =>
    import('./views/lincoln-calm-view/lincoln-calm-view')
);

const RewardsViewHandler = loadable(() =>
    import('./views/rewards-view/rewards-view-handler')
);

export interface RouteDescription {
    path: string | string[];
    exact: boolean;
    component?:
        | React.ComponentType<RouteComponentProps<any>>
        | React.ComponentType<any>;
    render?: (props: RouteComponentProps<any>) => React.ReactNode;
    alias: string;
}

// IMPORTANT - if you are adding new route to DEFAULT_REGISTER,
// please check if it should also be added in router-configuration.ts
export const DEFAULT_REGISTER: Array<RouteDescription> = [
    {
        path: '/vehicle/vin/not-found',
        exact: true,
        alias: 'VehicleNotFoundView',
        component: VehicleNotFoundView,
    },
    {
        path: '/fm-vehicle/vin/:vin',
        exact: true,
        component: FMVehicleVinView,
        alias: 'FMVehicleVinView',
    },
    {
        path: '/vehicle/vin/:vin',
        exact: true,
        component: VehicleVinView,
        alias: 'VehicleVinView',
    },
    {
        path: '/vehicle-details-preimagine',
        exact: true,
        component: PreImagineVehicleVinView,
        alias: 'PreImagineVehicleVinView',
    },
    {
        path: '/vehicle-dashboard',
        exact: true,
        component: VehicleDashboardView,
        alias: 'VehicleDashboardView',
    },
    {
        path: '/fm-vehicle-dashboard',
        exact: true,
        component: FMVehicleDashboardView,
        alias: 'FMVehicleDashboardView',
    },
    {
        path: ['/warranty', '/warranty/', '/warranty/:vin'],
        exact: true,
        component: WarrantyWrapper,
        alias: 'WarrantyWrapper',
    },
    {
        path: '/warranty',
        exact: true,
        component: WarrantyInformationViewRoute,
        alias: 'WarrantyInformationViewRoute',
    },
    {
        path: '/extended-service-business',
        exact: true,
        component: ExtendedServiceBusinessRoute,
        alias: 'ExtendedServiceBusinessRoute',
    },
    {
        path: ['/service-history', 'service-history-check'],
        exact: false,
        component: ServiceHistoryView,
        alias: 'ServiceHistoryView',
    },
    {
        path: '/vehicle-health/oil-life-details',
        exact: true,
        component: OilLifeDetailsView,
        alias: 'OilLifeDetailsView',
    },
    {
        path: '/vehicle-health/slow-tire-leak-details',
        exact: true,
        component: SlowTireLeakDetailsView,
        alias: 'SlowTireLeakDetailsView',
    },
    {
        path: '/vehicle-health/service-reminder',
        exact: true,
        component: ServiceRemindersDetailsView,
        alias: 'ServiceRemindersDetailsView',
    },
    {
        path: [
            '/vehicle-health/powertrain',
            '/vehicle-health/brakes-suspensions',
            '/vehicle-health/fluids-filters',
            '/vehicle-health/restraints-driver-assistance',
        ],
        exact: true,
        component: DiagnosticsDetailsView,
        alias: 'DiagnosticsDetailsView',
    },
    {
        path: ['/vehicle-health', '/vehicle-health/:vin'],
        exact: true,
        component: VehicleHealthView,
        alias: 'VehicleHealthView',
    },
    {
        path: '/vehicle-health-report',
        exact: true,
        component: VehicleHealthReportView,
        alias: 'VehicleHealthReportView',
    },
    {
        path: [
            '/rewards',
            '/fordpass/fordpass-rewards',
            '/lincoln-way-app/lincoln-access-rewards',
        ],
        exact: false,
        render: function() {
            return <RewardsViewHandler />;
        },
        alias: 'RewardsViewHandler',
    },
    {
        path: [
            '/service-maintenance/tire-finder/',
            '/service-maintenance/tire-finder/search-results.html',
            '/service-maintenance/tire-finder/ecommerce.html',
        ],
        exact: true,
        component: TireFinderView,
        alias: 'TireFinderView',
    },
    {
        path: '/ford-rides',
        exact: false,
        component: FordRidesView,
        alias: 'FordRidesView',
    },
    //{
    //path: '/vhr-report',
    //exact: false,
    //component: VehicleHealthReportView,
    //alias: 'VehicleHealthReportView',
    //},

    {
        path: '/recalls-details/:vinOrModel?/:year?/',
        exact: false,
        component: RecallsDetailsPage,
        alias: 'RecallsDetailsPage',
    },

    {
        path: '/campaign-details/:id',
        exact: false,
        component: RecallsCampaignDetailsPage,
        alias: 'RecallsCampaignDetailsPage',
    },
    {
        path: '/service-maintenance/service-rebates/redeem-rebates/',
        exact: true,
        alias: 'RedeemRebatesView',
        component: RedeemRebatesView,
    },
    {
        path: '/service-maintenance/coupons-offers-rebates/offers/',
        exact: true,
        component: CouponsDetailView,
        alias: 'CouponsDetailView',
    },
    {
        path: '/service-maintenance/coupons-offers-rebates/',
        exact: false,
        component: CouponsView,
        alias: 'CouponsView',
    },
    {
        path: '/privacy-center/privacy-policy',
        exact: true,
        component: PrivacyPolicyPortalView,
        alias: 'PrivacyPolicyPortalView',
    },
    {
        path: '/privacy-options',
        exact: true,
        alias: 'PrivacyOptionsView',
        component: PrivacyOptionsView,
    },
    {
        path: '/privacy-center',
        exact: true,
        alias: 'PrivacyCenterView',
        component: PrivacyCenterView,
    },
    {
        path: '/recalls/',
        exact: true,
        component: RecallsPage,
        alias: 'RecallsPage',
    },
    {
        path: '/recalls/:vin/',
        exact: true,
        component: RecallsPage,
        alias: 'RecallsWithVin',
    },
    {
        path: '/recalls/',
        exact: true,
        component: TakataRecallsPage,
        alias: 'TakataRecallsPage',
    },
    {
        path: '/recalls/:vin/',
        exact: true,
        component: TakataRecallsPage,
        alias: 'TakataRecallsWithVin',
    },
    {
        path: '/owner-manuals/',
        exact: true,
        component: OwnerManualPage,
        alias: 'OwnerManualPage',
    },
    {
        path: '/vehicle/:model+/:year/owner-manuals/',
        exact: true,
        component: OwnerManualDetailsPage,
        alias: 'OwnerManualDetailsPage',
    },
    {
        path: '/owner-manuals/owner-manuals-sitemap/',
        exact: true,
        component: OwnerManualSitemapPage,
        alias: 'OwnerManualSitemapPage',
    },
    {
        path: '/owner-manuals-details/',
        exact: true,
        component: OwnerManualDetailsPage,
        alias: 'OwnerManualWithVin',
    },
    {
        path: '/owner-manuals-details/intelligent-search/',
        exact: true,
        component: OwnerManualAiChat,
        alias: 'OwnerManualAiChat',
    },
    {
        path: '/coming-soon/',
        exact: true,
        component: ComingSoonPage,
        alias: 'ComingSoonPage',
    },
    {
        path: '/vehicle/:model/:year/discover-your-ford/livestream',
        exact: false,
        component: LiveStreamView,
        alias: 'LiveStreamView',
    },
    {
        path: ['/fordpass', '/lincoln-way'],
        exact: false,
        component: DeviceAwareNaEuView,
        alias: 'DeviceAwareNaEuView',
    },
    {
        path: '/',
        exact: true,
        component: HomePage,
        alias: 'HomePage',
    },
    {
        path: '/home-preimagine',
        exact: true,
        component: HomePreimagine,
        alias: 'HomePreimagine',
    },
    {
        path: '/pro-trailer-backup-assist/',
        exact: true,
        component: ProTrailerBackupAssist,
        alias: 'ProTrailerBackupAssist',
    },
    {
        path: '/learning-hub/',
        exact: true,
        component: CxHomePage,
        alias: 'CxHomePage',
    },
    {
        path: '/learning-hub/vehicle/:model/:year',
        exact: false,
        alias: 'CxHandler',
        component: CxHandler,
    },
    {
        path: '/how-to-videos/',
        exact: true,
        alias: 'HowToVideoView',
        component: HowToVideoView,
    },
    {
        path: `/vehicle/:model/:year/how-to-videos/video-library/`,
        exact: true,
        component: HTVVideoLibraryView,
        alias: 'HTVVideoLibraryView',
    },
    {
        path: `/vehicle/:model/:year/how-to-videos/video-library/:categoryName/:videoId/`,
        exact: true,
        alias: 'HowToViewVideo',
        component: HowToViewVideo,
    },
    {
        path: `/vehicle/:model/:year/how-to-videos/video-library/:categoryName/`,
        exact: true,
        alias: 'HTVCategoryLibrary',
        component: HTVCategoryLibrary,
    },
    {
        path: '/how-to-videos/video-sitemap/',
        exact: true,
        alias: 'SiteMapView',
        component: SiteMapView,
    },

    {
        path: ['/discover-your-ford/scheduling'],
        exact: true,
        render: function(props) {
            return <GuidedVirtualTour {...props} pageType="landing" />;
        },
        alias: 'GuidedVirtualTour',
    },
    {
        path: [
            `/discover-your-${brand.toLowerCase()}/scheduling/new-appointment`,
        ],
        exact: true,
        render: function(props) {
            return <GuidedVirtualTour {...props} pageType="new-appointment" />;
        },
        alias: 'GvtCreateAppointment',
    },
    {
        path: '/discover-your-ford/scheduling/cancel',
        exact: true,
        render: function(props) {
            return (
                <GuidedVirtualTour {...props} pageType="cancel-appointment" />
            );
        },
        alias: 'GvtCancelConfirmation',
    },
    {
        path: [`/discover-your-${brand.toLowerCase()}/scheduling/edit`],
        exact: true,
        render: function(props) {
            return <GuidedVirtualTour {...props} pageType="edit-appointment" />;
        },
        alias: 'GVTEditAppointment',
    },
    {
        path: [
            `/discover-your-${brand.toLowerCase()}/scheduling/new-appointment`,
        ],
        exact: true,
        render: function(props) {
            return <GuidedVirtualTour {...props} pageType="new-appointment" />;
        },
        alias: 'GVTCreateAppointment',
    },
    {
        path: [
            `/discover-your-${brand.toLowerCase()}/scheduling/appointment-details`,
        ],
        exact: true,
        render: function(props) {
            return (
                <GuidedVirtualTour {...props} pageType="appointment-details" />
            );
        },
        alias: 'GVTAppointmentDetails',
    },
    {
        path: [`/vehicle/:model/:year/discover-your-${brand.toLowerCase()}`],
        exact: true,
        render: function(props) {
            return (
                <DyfLandingView
                    {...props}
                    flow={props.match.params.year ? 'ymm' : 'vin'}
                />
            );
        },
        alias: 'DyfLandingView',
    },
    {
        path: [`/vehicle/vin/:vin/discover-your-${brand.toLowerCase()}`],
        exact: true,
        render: function(props) {
            return (
                <DyfLandingView
                    {...props}
                    flow={props.match.params.vin ? 'vin' : 'ymm'}
                />
            );
        },
        alias: 'DyfLandingView',
    },
    {
        path: [
            `/vehicle/:model/discover-your-${brand.toLowerCase()}/:title/overview/`,
            `/vehicle/:model/discover-your-${brand.toLowerCase()}/:categoryName/:title/overview/`,
        ],
        exact: false,
        render: function(props) {
            return <DyfFeatureArticleOverview {...props} flow="ymm" />;
        },
        alias: 'DyfYmmFeatureArticleOverview',
    },
    {
        path: [
            `/vehicle/vin/:vin/discover-your-${brand.toLowerCase()}/:title/overview/`,
            `/vehicle/vin/:vin/discover-your-${brand.toLowerCase()}/:categoryName/:title/overview/`,
        ],
        exact: true,
        render: function(props) {
            return <DyfFeatureArticleOverview {...props} flow="vin" />;
        },
        alias: 'DyfVinFeatureArticleOverview',
    },
    {
        path: [
            `/vehicle/vin/:vin/discover-your-${brand.toLowerCase()}/:title/related-articles/`,
            `/vehicle/vin/:vin/discover-your-${brand.toLowerCase()}/:categoryName/:title/related-articles/`,
        ],
        exact: true,
        component: DyfRelatedArticlesView,
        alias: 'DyfRelatedArticlesView',
    },
    {
        path: '/maintenance-schedule',
        exact: false,
        component: MaintenanceScheduleLandingView,
        alias: 'MaintenanceScheduleLandingView',
    },
    {
        path: ['/book-a-service', '/book-a-service/', '/book-a-service/:view'],
        exact: true,
        component: OsbWrapper,
        alias: 'OsbWrapper',
    },
    {
        path: '/book-a-service',
        exact: true,
        component: OsbStartView,
        alias: 'OsbStartView',
    },
    {
        path: '/book-a-service/dealer-step',
        exact: true,
        component: OsbDealerView,
        alias: 'OsbDealerView',
    },
    {
        path: '/book-a-service/services-step',
        exact: true,
        component: OsbServicesView,
        alias: 'OsbServicesView',
    },
    {
        path: '/book-a-service/booking-confirmation',
        exact: true,
        component: OsbBookingConfirmationView,
        alias: 'OsbBookingConfirmationView',
    },
    {
        path: '/book-a-service/datetime-step',
        exact: true,
        component: OsbCalenderView,
        alias: 'OsbCalenderView',
    },
    {
        path: '/book-a-service/cancel-booking-confirmation',
        exact: true,
        component: OsbBookingCancelView,
        alias: 'OsbBookingCancelView',
    },
    {
        path: '/book-a-service/delivery-step',
        exact: true,
        component: OsbDeliveryView,
        alias: 'OsbDeliveryView',
    },
    {
        path: '/book-a-service/location-step',
        exact: true,
        component: OsbServiceLocationView,
        alias: 'OsbServiceLocationView',
    },
    {
        path: '/book-a-service/personal-details-step',
        exact: true,
        component: OsbPersonalDetailsView,
        alias: 'OsbPersonalDetailsView',
    },
    {
        path: '/book-a-service/retrieve-access-code',
        exact: true,
        component: OsbBookingAccessCodeView,
        alias: 'OsbBookingAccessCodeView',
    },
    {
        path: '/book-a-service/retrieve-booking-step',
        exact: true,
        component: OsbBookingRetrieveView,
        alias: 'OsbBookingRetrieveView',
    },
    {
        path: '/book-a-service/retrieve-booking-summary',
        exact: true,
        component: OsbBookingRetrieveSummaryView,
        alias: 'OsbBookingRetrieveSummaryView',
    },
    {
        path: '/book-a-service/review-booking',
        exact: true,
        component: OsbBookingReviewView,
        alias: 'OsbBookingReviewView',
    },
    {
        path: '/book-a-service/vehicle-step',
        exact: true,
        component: OsbVehicleView,
        alias: 'OsbVehicleView',
    },
    {
        path: '/collision-assistance',
        exact: false,
        component: CollisionAssistanceView,
        alias: 'CollisionAssistanceView',
    },
    {
        path: '/towing-calculator',
        exact: false,
        component: CITTView,
        alias: 'CITTView',
    },
    {
        path: '/category/fordpass/trailer-profiles/early-access',
        exact: false,
        component: TowingExperienceView,
        alias: 'TowingExperienceView',
    },
    {
        path: '/category/fordpass/checklist-to-connect-trailer',
        exact: true,
        component: TrailerChecklistView,
        alias: 'TrailerChecklistView',
    },
    {
        path:
            '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
        exact: true,
        render: function renderTrailerChecklistHandler() {
            return (
                <TrailerChecklistDetailHandler
                    trailerType={TrailerType.CONVENTIONAL}
                />
            );
        },
        alias: 'TrailerChecklistConventionalTrailer',
    },
    {
        path:
            '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
        exact: true,
        render: function renderTrailerChecklistHandler() {
            return (
                <TrailerChecklistDetailHandler
                    trailerType={TrailerType.GOOSENECK}
                />
            );
        },
        alias: 'TrailerChecklistGooseneckTrailer',
    },
    {
        path:
            '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
        exact: true,
        render: function renderTrailerChecklistHandler() {
            return (
                <TrailerChecklistDetailHandler
                    trailerType={TrailerType.FIFTHWHEEL}
                />
            );
        },
        alias: 'TrailerChecklistFifthWheelTrailer',
    },
    {
        path: '/category/service-maintenance/collision-assistance*',
        exact: true,
        render: function renderSamCategoryHandler() {
            return (
                <SamCategoryHandler
                    useParams={useParams}
                    useExperienceContent={useExperienceContent}
                    useCategoryViewContent={useSamCategoryViewContent}
                    useSubCategoryViewContent={useSubCategoryViewContent}
                ></SamCategoryHandler>
            );
        },
        alias: 'SamCategoryHandler',
    },
    {
        path: '/category/*',
        exact: false,
        render: function renderCategoryHandler() {
            return (
                <CategoryHandler
                    useParams={useParams}
                    useExperienceContent={useExperienceContent}
                    useCategoryViewContent={useCategoryViewContent}
                    useSubCategoryViewContent={useSubCategoryViewContent}
                ></CategoryHandler>
            );
        },
        alias: 'CategoryHandler',
    },
    {
        path: '/fm-vehicle/:model/:year',
        exact: true,
        alias: 'FMVehicleYmmView',
        component: FMVehicleYmmView,
    },
    {
        path: '/vehicle/:model/:year',
        exact: true,
        alias: 'VehicleYmmView',
        component: VehicleYmmView,
    },
    {
        path: '/:howtos/error-page',
        exact: false,
        component: RoadBlockPage,
        alias: 'RoadBlockPage',
    },
    {
        path: '/sitemap',
        exact: true,
        component: HtmlSiteMap,
        alias: 'HtmlSiteMap',
    },
    {
        path: '/how-tos/sitemap',
        exact: true,
        component: AstuteSiteMap,
        alias: 'AstuteSiteMap',
    },
    {
        path: '/how-tos/search/:searchterm',
        exact: true,
        component: AstuteSearch,
        alias: 'AstuteSearch',
    },
    {
        path: '/how-tos/:category',
        exact: true,
        component: CategoryPageView,
        alias: 'CategoryPageView',
    },
    {
        path: '/how-tos/:category/:subcategory',
        exact: true,
        component: SubCategoryPageView,
        alias: 'SubCategoryPageView',
    },
    {
        path: '/how-tos/:category/:subcategory/:topic',
        exact: true,
        alias: 'AstuteTopic',
        component: AstuteTopic,
    },
    {
        path: ['/sync-maps-updates'],
        exact: true,
        alias: 'SyncMapsUpdatesView',
        component: SyncMapsUpdatesView,
    },
    {
        path: '/schedule-service',
        exact: true,
        alias: 'GxpCustomUIView',
        component: GxpCustomUIView,
    },
    {
        path: '/service-information',
        exact: true,
        alias: 'TSBView',
        component: TSBView,
    },
    {
        path: '/yfa-optin',
        exact: true,
        alias: 'YFAOptInView',
        component: YFAOptInView,
    },
    {
        path: '/yfa-success',
        exact: true,
        alias: 'YFAConfirmationView',
        component: YFAConfirmationView,
    },
    {
        path: '/yfa-enrolled',
        exact: true,
        alias: 'YFAEnrolledView',
        component: YFAEnrolledView,
    },
    {
        path: '/yfa-invited',
        exact: true,
        alias: 'YfaInvitedView',
        component: YfaInvitedView,
    },
    {
        path: '/yfa-terms',
        exact: true,
        alias: 'YfaTermsConditions',
        component: YfaTermsConditions,
    },
    {
        path: '/yfa-error',
        exact: true,
        alias: 'YfaErrorView',
        component: YfaErrorView,
    },
    {
        path: '/service-calculator',
        exact: true,
        alias: 'ServicePriceCalculatorView',
        component: ServicePriceCalculatorView,
    },
    {
        path: '/lincoln-calm',
        exact: true,
        alias: 'LincolnCalmView',
        component: LincolnCalmView,
    },
    {
        path: '/bluecruise',
        exact: true,
        alias: 'BlueCruisePage',
        component: BlueCruisePage,
    },
    {
        path: '/software-update-release-notes-history',
        exact: true,
        alias: 'HistoricalReleaseNotes',
        component: HistoricalReleaseNotes,
    },
    {
        path: '*',
        exact: false,
        component: PageNotFoundView,
        alias: 'PageNotFoundView',
    },
];

DEFAULT_REGISTER.forEach(Object.freeze);

const Routes = () => {
    const serverContext = useContext(ServerContext);
    configs.forEach(Object.freeze);
    const mergedRegister = mergeRoutes(
        DEFAULT_REGISTER,
        configs,
        serverContext.domain || currentDomain,
        brandUtil.getBrandName(serverContext.brand || brand),
        serverContext.currentLanguageRegionCode || currentLanguageRegionCode
    );
    serverContext.pageNotFound = false;
    const routes = mergedRegister.map((routeDescription: RouteDescription) => (
        <Route
            key={
                routeDescription.path instanceof Array
                    ? routeDescription.path.join('')
                    : routeDescription.path
            }
            {...routeDescription}
        />
    ));

    return (
        <Switch>
            {routes}
            <Route path="*" component={PageNotFoundView} />
        </Switch>
    );
};

export default Routes;

export const findByAlias = findRoute(
    findRouteByAlias,
    DEFAULT_REGISTER,
    configs,
    currentDomain,
    brandUtil.getBrandName(brand),
    currentLanguageRegionCode
);

export const findByPath = findRoute(
    findAliasByPath,
    DEFAULT_REGISTER,
    configs,
    currentDomain,
    brandUtil.getBrandName(brand),
    currentLanguageRegionCode
);

export const findByPathV1 = (
    path: string,
    domain: string,
    brand: string,
    locale: string
) => {
    const mergedRoutes = mergeRoutes(
        DEFAULT_REGISTER,
        configs,
        domain,
        new BrandUtil().getBrandName(brand),
        locale
    );
    return findAliasByPath(path, mergedRoutes);
};

export const findByAliasV1 = (
    alias: string,
    domain: string,
    brand: string,
    locale: string
) => {
    const mergedRoutes = mergeRoutes(
        DEFAULT_REGISTER,
        configs,
        domain,
        new BrandUtil().getBrandName(brand),
        locale
    );
    return findRouteByAlias(alias, mergedRoutes);
};

export const buildPath = buildRoute(findByAlias);
